body, html, #root {
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background: #222222;
  color: white;
}

.p-paginator .p-paginator-current {
  margin-right: auto;
}

.p-paginator .p-dropdown {
  margin-left: auto;
}

.p-component, .p-link {
  font-size: .8rem !important;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  cursor: pointer;
}

.p-breadcrumb {
  padding: 0;
  border: 0;
}

.p-panelmenu .p-panelmenu-header > a {
  background: none;
  border: none;
  border-radius: 0;
}

.p-divider {
  height: 1px;
  background-color: #DDDDDD;
}

.p-tabmenu {
  min-height: 55px;
}

.surface-overlay {
  z-index: 999;

  > div {
    background-color: inherit;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    background-color: #000;
    opacity: 0.1;
    z-index: -1;
  }
}

.submissions .p-row-editor-init {
  display: none;
}

.p-sidebar {
  min-width: 50vw;

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-custom-header {
    margin: 0 -1.25rem;

    button {
      background: transparent !important;
      box-shadow: none !important;
    }
  }
}

